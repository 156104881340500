/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  TablesWidget13,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  ChartsWidget2,
  ChartsWidget4,
} from '../../../_metronic/partials/widgets'
import CountUp from 'react-countup'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 mb-10'>
      <div className='col-xl-4 col-sm-6'>
        <div className='card card-dashboard'>
          <div className='card-body p-4'>
            <div className='row'>
              <div className='col-8'>
                <div className='numbers'>
                  <p className='fs-5 mb-0 text-capitalize fw-semibold text-gray-400'>Sales</p>
                  <h5 className='fw-bold mb-0 fs-1'>
                    <span>
                      $
                      <CountUp start={0} end={103430} enableScrollSpy scrollSpyOnce>
                        {({countUpRef}) => <span ref={countUpRef} />}
                      </CountUp>
                    </span>
                    <span className='text-success text-sm fw-bold fs-3 ms-2'>
                      +
                      <CountUp start={0} end={5} enableScrollSpy scrollSpyOnce>
                        {({countUpRef}) => <span ref={countUpRef} />}
                      </CountUp>
                      %
                    </span>
                  </h5>
                </div>
              </div>
              <div className='col-4 text-end d-flex justify-content-center align-items-center'>
                <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex justify-content-center align-items-center ms-auto'>
                  <i
                    className='fs-2 text-white fa-solid fa-circle-dollar-to-slot'
                    aria-hidden='true'
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-4 col-sm-6'>
        <div className='card card-dashboard'>
          <div className='card-body p-4'>
            <div className='row'>
              <div className='col-8'>
                <div className='numbers'>
                  <p className='fs-5 mb-0 text-capitalize fw-semibold text-gray-400'>
                    Today's Users
                  </p>
                  <h5 className='fw-bold mb-0 fs-1'>
                    <span>
                      <CountUp start={0} end={2350} enableScrollSpy scrollSpyOnce>
                        {({countUpRef}) => <span ref={countUpRef} />}
                      </CountUp>
                    </span>
                    <span className='text-success text-sm fw-bold fs-3 ms-2'>
                      +
                      <CountUp start={0} end={12} enableScrollSpy scrollSpyOnce>
                        {({countUpRef}) => <span ref={countUpRef} />}
                      </CountUp>
                      %
                    </span>
                  </h5>
                </div>
              </div>
              <div className='col-4 text-end d-flex justify-content-center align-items-center'>
                <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex justify-content-center align-items-center ms-auto'>
                  <i className='fs-2 text-white fa-solid fa-user-plus' aria-hidden='true'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-4 col-sm-6'>
        <div className='card card-dashboard'>
          <div className='card-body p-4'>
            <div className='row'>
              <div className='col-8'>
                <div className='numbers'>
                  <p className='fs-5 mb-0 text-capitalize fw-semibold text-gray-400'>New Clients</p>
                  <h5 className='fw-bold mb-0 fs-1'>
                    <span>
                      +
                      <CountUp start={0} end={3462} enableScrollSpy scrollSpyOnce>
                        {({countUpRef}) => <span ref={countUpRef} />}
                      </CountUp>
                    </span>
                    <span className='text-danger text-sm fw-bold fs-3 ms-2'>
                      -
                      <CountUp start={0} end={4} enableScrollSpy scrollSpyOnce>
                        {({countUpRef}) => <span ref={countUpRef} />}
                      </CountUp>
                      %
                    </span>
                  </h5>
                </div>
              </div>
              <div className='col-4 text-end d-flex justify-content-center align-items-center'>
                <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex justify-content-center align-items-center ms-auto'>
                  <i className='fs-2 text-white fa-solid fa-user-secret' aria-hidden='true'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10 mb-10 mb-xl-0'>
      <div className='col-xxl-6 mb-0 mb-xl-10'>
        <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-0' />
      </div>
      <div className='col-xxl-6 mb-0 mb-xl-10'>
        <ChartsWidget2 className='card-xl-stretch' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      <div className='col mb-5 mb-xl-10'>
        <TablesWidget13 className={''} />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      {/* <DashboardPage /> */}
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p>Coming Soon</p>
      </div>
    </>
  )
}

export {DashboardWrapper}
