/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
const SidebarMenuMain = () => {
  const intl = useIntl()
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen013.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen049.svg'
        title='User management'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='User Roles & Rights' hasBullet={true} />
        <SidebarMenuItem to='/apps/user-management/users' title='Customer Users' hasBullet={true} />
        <SidebarMenuItem to='' title='Agent Users' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen073.svg'
        title='General Settings'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='Cuurency' hasBullet={true} />
        <SidebarMenuItem to='' title='Module' hasBullet={true} />
        <SidebarMenuItem to='' title='Loyality Points' hasBullet={true} />
        <SidebarMenuItem to='' title='Multi Launguage Support' hasBullet={true} />
        <SidebarMenuItem to='' title='Markup Management' hasBullet={true} />
        <SidebarMenuItem to='' title='Payment Gateway' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen075.svg'
        title='Flights'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='Flight Suppliers' hasBullet={true} />
        <SidebarMenuItem to='' title='Airports' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/general/gen051.svg'
        title='Bookings'
        fontIcon='bi-layers'
      />
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen007.svg'
        title='Subscription Management'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='User Subscription' hasBullet={true} />
        <SidebarMenuItem to='' title='Agent Subscription' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen070.svg'
        title='Customer Support'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem
          to='/apps/customer-support/CustomerInquiries'
          title='Customer Inquires'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen055.svg'
        title='Content Management'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='Airline Content Management' hasBullet={true} />
        <SidebarMenuItem to='' title='Website Content Management' hasBullet={true} />
        <SidebarMenuItem to='' title='SMS & Email Template' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen051.svg'
        title='Coupons & Offers'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='Coupon Codes' hasBullet={true} />
        <SidebarMenuItem to='' title='Offers' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='/media/icons/duotune/general/gen032.svg'
        title='Reports'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='' title='Activity Logs' hasBullet={true} />
        <SidebarMenuItem to='' title='TBA' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
