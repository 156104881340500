import { atom } from "recoil";
export const accessToken = atom({
    key: 'accessToken',
    default: [],
  });
  export const eyeClicked = atom({
    key: 'eyeClicked',
    default: false,
  });
  export const editClicked = atom({
    key: 'editClicked',
    default: false,
  });
   export const idSub = atom({
    key: 'idSub',
    default: '',
  });
  export const firstNameSub = atom({
    key: 'firstNameSub',
    default: '',
  });
  export const lastNameSub = atom({
    key: 'lastNameSub',
    default: 'asdasd',
  });
  export const emailSub = atom({
    key: 'emailSub',
    default: '',
  });
  export const phoneNumberSub = atom({
    key: 'phoneNumberSub',
    default: '',
  });
  export const submitClicked = atom({
    key:'submitClicked',
    default:false
  })
  export const takeCustomerInquiry = atom({
    key:'takeCustomerInquiry',
    default:10
  })
  export const skipCustomerInquiry = atom({
    key:'skipCustomerInquiry',
    default:0
  })
  export const filterOptionCustomerInquiry = atom({
    key:'filterOptionCustomerInquriy',
    default:'ASC'
  })
  export const filterByCustomerInquiry = atom({
    key:'filterByCustomerInquriy',
    default:'first_name'
  })
  export const applyFilterCustomerInquiry = atom({
    key:'applyFilterCustomerInquriy',
    default:false
  })
  export const applyFilterSubscriberUser = atom({
    key:'applyFilterSubscriberUser',
    default:false
  })
  export const statusSubscriberUser = atom({
    key:'statusSubscriberUser',
    default:"true"
  })
  export const exportFileSubscriberUser = atom({
    key:'exportFileSubscriberUser',
    default:false
  })
  export const offsetSusbcriberUser= atom({
    key:'offsetSusbcriberUser',
    default:0
  })
  export const orderBySubscriberUser = atom({
    key:'orderBySubscriberUser',
    default:'created_at'
  })
  export const orderDirSusbcriberUser = atom({
    key:'orderDirSusbcriberUser',
    default:'DESC'
  })
  export const roleIdSubscriberUser = atom({
    key:'roleIdSubscriberUser',
    default:0
  })
  export const limitSubscriberUser = atom({
    key:'limitSubscriberUser',
    default:3000
  })
  export const showSubscribersDataState = atom({
    key:'showSubscribersDataState',
    default:false
  })
  export const searchQuerySubscriberUser = atom({
    key:'searchQuerySubscriberUser',
    default:''
  })
  export const currentPageSubscriberUser = atom({
    key:'currentPageSubscriberUser',
    default:1
  })
  export const itemsPerPageSubscriberUser = atom({
    key:'itemsPerPageSubscriberUser',
    default:10  
  })