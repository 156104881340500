import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import LoginImage from '../../../_metronic/assets/Images/authentication.svg';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className='d-flex flex-column flex-lg-row-reverse flex-column-fluid'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
      </div>
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <img style={{height:'80vh'}}  src={LoginImage} alt='Login' />
        </div>
      </div>
    </div>
  );
};
export { AuthLayout };