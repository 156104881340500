import axios from 'axios'
const APIS_URL =  process.env.REACT_APP_ADMIN_API_URL
export const LOGIN_URL = `${APIS_URL}/auth/admin-login`
export const REGISTER_URL = `${APIS_URL}/user/add`
export const REQUEST_PASSWORD_URL = `${APIS_URL}/auth/forgot-password`

export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

